var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"info-box mb-3"},[_c('div',{staticClass:"info-box-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"text-muted mb-1 font-13 text-uppercase flex"},[_vm._v(" Judul ")]),_c('h4',{staticClass:"mb-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.dataUjian.ujian_title)+" ")])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"text-muted mb-1 font-13 text-uppercase flex"},[_vm._v(" Mapel ")]),_c('h4',{staticClass:"mb-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.dataUjian.mapel)+" ")])])])])])])])]),_c('div',{staticClass:"card card-info"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"box-tools card-tools toolbar"},[_c('div',{staticClass:"btn-group"},[_vm._m(0),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('router-link',{attrs:{"to":{
              name: 'Form Soal',
              params: { ujian_id: _vm.ujian_id },
              }}},[_c('button',{staticClass:"btn btn-sm btn-primary dropdown-item",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" Buat Manual ")])]),_c('router-link',{attrs:{"to":{
              name: 'Form Import Soal',
              params: { ujian_id: _vm.ujian_id },
              }}},[_c('button',{staticClass:"btn btn-sm btn-primary dropdown-item",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-upload"}),_vm._v(" Import ")])])],1)])])]),_c('div',{staticClass:"card-body"},[_c('table',{ref:"tblsoal",staticClass:"table table-hover"},[_vm._m(1),_c('tbody',{on:{"click":_vm.handleClick}})]),_c('router-link',{staticClass:"btn btn-secondary",attrs:{"to":{ path: '/list-ujian' }}},[_vm._v(" Kembali ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-primary dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Buat Soal ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("NOMOR SOAL")]),_c('th',[_vm._v("SOAL")]),_c('th',[_vm._v("OPSI JAWABAN")]),_c('th',[_vm._v("TINDAKAN")])])])
}]

export { render, staticRenderFns }