<template>
  <section class="content">
    <div class="row mb-2">
        <div class="col-md-12">
          <div class="info-box mb-3">
            <div class="info-box-content">
              <div class="row">
                <div class="col-md-12">
                    <div class="text-muted mb-1 font-13 text-uppercase flex">
                        Judul
                    </div>
                    <h4 class="mb-1">
                    <span> {{ dataUjian.ujian_title }} </span>
                    </h4>
                </div>
                <div class="col-md-12">
                    <div class="text-muted mb-1 font-13 text-uppercase flex">
                        Mapel
                    </div>
                    <h4 class="mb-1">
                    <span> {{ dataUjian.mapel }} </span>
                    </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="card card-info">
    <div class="card-header">
        <div class="box-tools card-tools toolbar">
            <div class="btn-group">
        <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-plus"></i> Buat Soal
        </button>
        <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{
                name: 'Form Soal',
                params: { ujian_id: ujian_id },
                }">
                <button type="button" class="btn btn-sm btn-primary dropdown-item" > 
                <i class="fas fa-edit"></i> Buat Manual
            </button>
            </router-link> 
            <router-link :to="{
                name: 'Form Import Soal',
                params: { ujian_id: ujian_id },
                }">
                <button type="button" class="btn btn-sm btn-primary dropdown-item" > 
                <i class="fas fa-upload"></i> Import
            </button>
            </router-link> 
        </div>
        </div>
        </div>
    </div>

      <div class="card-body">
        <table class="table table-hover" ref="tblsoal">
            <thead>
              <tr>
                <th>NOMOR SOAL</th>
                <th>SOAL</th>
                <th>OPSI JAWABAN</th>
                <th>TINDAKAN</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
        </table>
        <router-link
          :to="{ path: '/list-ujian' }"
          class="btn btn-secondary"
        >
          Kembali
        </router-link>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import Daterangepicker from 'daterangepicker';
import "daterangepicker/daterangepicker.css";
import moment from "moment";

export default {
  name: "",
  data() {
    return {
      dataUjian: {},
      errors: [],
      optMapel: [],
      method: "",
      roles: "",
      ujian_id: "",
      formTitle: "Tambah",
      form: {
        mapel_id: "",
        ujian_type: "",
        ujian_title: "",
        tanggal_mulai: "",
        tanggal_selesai: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
    this.ujian_id = this.$route.params.id;

    this.loadDetail(this.$route.params.id);
  },
  components: { 
    vSelect, 
  },
  methods: {
    loadDetail: function(id){
      authFetch("/akademik/ujian/" + id).then((res) => {
        res.json().then((json) => {
          this.dataUjian = json;
              console.log(this.dataUjian);
        });
      });
    },
    submitForm: function (ev) {
      const e = this.$refs;

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/akademik/ujian";
      if (this.method == "PUT")
        urlSubmit = "/akademik/ujian/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
    truncate: function (string, limit) {
      if (string.length <= limit) {
        return string;
      }
      return string.slice(0, limit) + "...";
    },
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }

      if (e.target.closest("button")) {
          if (e.target.dataset.action == "edit") {
            let id = e.target.dataset.id;
            this.$router.push({ path: `/soal/edit/${id}`, params: {ujian_id: this.$route.params.id}});
          } else if (e.target.dataset.action == "hapus") {
              Swal.fire({
              title: "Hapus soal?",
              icon: "question",
              denyButtonText: '<i class="fa fa-times"></i> Hapus',
              showCancelButton: true,
              showDenyButton: true,
              showConfirmButton: false,
            }).then((result) => {
              if (result.isDenied) {
                authFetch("/akademik/ujian/soal/" + e.target.dataset.id, {
                  method: "DELETE",
                  body: "id=" + e.target.dataset.id,
                })
                  .then((res) => {
                    return res.json();
                  })
                  .then((js) => {
                    this.table.api().ajax.reload();
                  });
              }
            });
          }
      }
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    
    new Daterangepicker(this.$refs.daterange, {
        startDate: moment(),
        endDate: moment().day(2),
        }, function(d1, d2) {
            self.form.tanggal_mulai = d1.format('DD/MM/YYYY')
            self.form.tanggal_selesai = d2.format('DD/MM/YYYY')
        });

    this.table = createTable(e.tblsoal, {
      title: "",
      roles: self.$route.params.roles,
      frame: false,
      selectedRowClass: "",
      ajax: "/akademik/ujian/soal",
      paramData: function(d) {
        if (self.$route.params.id) {
           // d.pickup = self.og_status == 0 ? 0 : 1;
           //d.pickup = 0;
            d.ujian_id = self.$route.params.id;
        } else {
           //d.pickup = -1;
           d.ujian_id = 0; //self.$route.params.id;
        }
      },
      columns: [
        { data: "nomor_soal",
          width: "10px",
          render: function (data, type, row, meta) {
            return `<h5 class="text-center">${data}</h5>`
          }},
        { data: "soal" },
        { data: "opsi_jawaban",
        render: function (data, type, row, meta) {
          return data + " opsi";
        }, },
        { data: "action",
          sortable: false,
        render: function (data, type, row, meta) {
          let dropdown =
          `<div class="btn-group">
          <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Klik
          </button>
          <div class="dropdown-menu dropdown-menu-right">
          <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="edit" data-id="` +
                  row.id +
                  `"> Edit </button>
          <button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="hapus" data-id="` +
                  row.id +
                  `">Hapus</button>
          </div>
          </div>`;

          return dropdown;

            // return `<div class="btn-group"><button type="button" class="btn btn-sm btn-outline-info" data-action="edit" data-id="`+row.id+`"><i class="fas fa-eye"></i> Detail</button></div>`;
        }, },
      ],
      filter: false,
      // filterBy: [1, 2, 5],
      rowCallback: function (row, data) {},
    });
  },
};
</script>
